import {
  REACT_APP_API_BASE_URL
} from '../configs';
import { APIApi, LessonApi, ResourcesApi } from './server';
import { Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';

export const getToken = async () => {
  let token = '';
  try {
    const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
    token = user.getSignInUserSession()?.getIdToken().getJwtToken() ?? '';
  } catch (error) {
    console.log('getToken error: ', error);
  }
  return token;
};
export const axiosOptions = async () => {
  return { headers: { Authorization: `Bearer ${getToken()}` } };
};
export const serverApi = (() => {
  // FIXME Amplifyの設定を別ファイルにまとめたい。（Repositoryパターンのように使う側がAmplifyを意識しない形に。）
  // Amplify.configure({
  //   region: REACT_APP_COGNITO_REGION,
  //   userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
  //   userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  // });
  return new APIApi({
    isJsonMime: (mime: string): boolean => {
      const jsonMime: RegExp = new RegExp(`^(application/json|[^;/ \t]+/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i'`);
      return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    },
    basePath: REACT_APP_API_BASE_URL,
    baseOptions: axiosOptions(),
  });
})();
export const resourcesApi = (() => {
  return new ResourcesApi({
    isJsonMime: (mime: string): boolean => {
      const jsonMime: RegExp = new RegExp(`^(application/json|[^;/ \t]+/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i'`);
      return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    },
    basePath: REACT_APP_API_BASE_URL,
    baseOptions: axiosOptions(),
  });
})();
export const lessonApi = (() => {
  return new LessonApi({
    isJsonMime: (mime: string): boolean => {
      const jsonMime: RegExp = new RegExp(`^(application/json|[^;/ \t]+/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i'`);
      return mime !== null && (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json');
    },
    basePath: REACT_APP_API_BASE_URL,
    baseOptions: axiosOptions(),
  });
})();
// 追加関数
export const requestServerApi = async <T>(callback: () => T) => {
  await serverApi.getAxiosOptions();
  return await callback();
};
export const requestResoucesApi = async <T>(callback: () => T) => {
  await resourcesApi.getAxiosOptions();
  return await callback();
};
export const requestLessonApi = async <T>(callback: () => T) => {
  await lessonApi.getAxiosOptions();
  return await callback();
};
