import '@aws-amplify/ui-react/styles.css';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
  Button,
  Image,
  HStack,
  VStack,
  Box,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiCircle } from 'react-icons/fi';
import { RxCross1 } from 'react-icons/rx';
import { ASSETS_IMG } from '../../../assets/assets';
import { getDisplayLangData } from '../../../component/json';
import { BaseQuestion } from '../../../_types/new-story';
import { KanaOption, LangOption } from '../index';
import { Answer } from '../../../interfaces/exerciseInterface';
import { localStorageKeys } from '../../../constant/localStorage';
import quiz_correct from '../../../assets/exercise-audios/quiz_correct.mp3';
import quiz_wrong from '../../../assets/exercise-audios/quiz_wrong.mp3';

interface ResultProps {
  baseQuestion: BaseQuestion;
  selectedAnswer: string;
  kanaOption: KanaOption;
  onCloseModal: () => void;
  handleNextQuestion: (answer: Answer) => void;
}
const ResultModal = ({ baseQuestion, selectedAnswer, kanaOption, onCloseModal, handleNextQuestion }: ResultProps) => {
  const { evaluteCorrect, correct } = baseQuestion;
  const Lang = getDisplayLangData();
  const circle_play_outline_white = ASSETS_IMG.circle_play_outline_white;
  const [systemLanguage, setSystemLanguage] = useState<LangOption>('ja');
  const result = evaluteCorrect === selectedAnswer;

  useEffect(() => {
    getSystemLanguage();
  }, []);
  
  useEffect(() => {
    //オーディオ再生処理
    const audio = new Audio(result ? quiz_correct : quiz_wrong);
    audio.play().catch((e) => console.error('オーディオ再生に失敗:', e));
    return () => {
      audio.pause();
      audio.currentTime = 0;
    };
  }, [result]); 


  const getSystemLanguage = () => {
    let systemLanguage = 'en';
    if (!localStorage.hasOwnProperty(localStorageKeys.systemLanguage)) {
      localStorage.setItem(localStorageKeys.systemLanguage, 'en');
    }
    systemLanguage = String(localStorage.getItem(localStorageKeys.systemLanguage));
    setSystemLanguage(systemLanguage as LangOption);
  };

  const nextQuestion = () => {
    const answer = result ? Answer.CORRECT : Answer.INCORRECT;
    handleNextQuestion(answer);
  };

  return (
    <>
      <Modal isOpen={true} onClose={onCloseModal} closeOnOverlayClick={false} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent h={'80vh'} w={{ base: '320px', sm: '400px' }} borderRadius="20px">
          <ModalHeader textAlign={'center'}></ModalHeader>
          <ModalBody h={'80vh'} overflowY="auto">
            <HStack gap={1} pb={3}>
              {result ? (
                <>
                  <FiCircle size="25px" strokeWidth="4" color="red" />
                  <Text fontWeight={'bold'} fontSize={'xl'}>
                    {'せいかい!'}
                  </Text>
                </>
              ) : (
                <>
                  <RxCross1 size="25px" strokeWidth="2" color="#2c93de" />
                  <Text fontWeight={'bold'} fontSize={'xl'}>
                    {'ざんねん...'}
                  </Text>
                </>
              )}
            </HStack>
            <VStack gap={1} alignItems="flex-start">
              <Box justifyContent="center" color="white" fontSize="sm" bgColor="orange" borderRadius={'2xl'} p={3}>
                <Text fontSize={'xl'}>{'こたえ'}</Text>
              </Box>
              {correct[kanaOption]?.split('\n').map((line, index) => (
                <Box key={index} fontWeight={'medium'} fontSize={'xl'} marginLeft={10}>
                  {line}
                </Box>
              )) || null}
              {correct[systemLanguage === 'ja' ? 'en' : systemLanguage]?.split('\n').map((line, index) => (
                <Box key={index} fontWeight={'medium'} fontSize={'xl'} marginLeft={10}>
                  {line}
                </Box>
              )) || null}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Flex justifyContent="center" w={'100%'}>
              <Button
                w="150px"
                h="60px"
                mb={'20px'}
                justifyContent="center"
                color="white"
                fontSize="sm"
                bgColor="orange"
                border={'1px solid black'}
                boxShadow={'0 3px black'}
                onClick={nextQuestion}
              >
                <Image mr="5px" src={circle_play_outline_white} sizes="md" h="20px"></Image>
                <Text fontSize={'15px'}>{Lang.KT_0024_05}</Text>
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ResultModal;
