import { ERROR_CODE } from './errorCode';

// TODO 一応ユーザー向けに表示できるような形式で作成
export const ERROR_MESSAGE: { [key in keyof typeof ERROR_CODE]: Localization } = {
  HTTP_401: {
    ja: '認証エラーが発生しました。',
    en: 'An authentication error has occurred.',
    vn: 'Đã xảy ra lỗi xác thực.',
  },
  HTTP_404: {
    ja: 'ページが見つかりません。',
    en: 'Page not found.',
    vn: 'Không tìm thấy trang.',
  },
  E_9000: {
    ja: '不明なエラーが発生しました。',
    en: 'Unknown error occurred.',
    vn: 'Xảy ra lỗi không xác định được.',
  },
  E_9001: {
    ja: '不明なエラーが発生しました。',
    en: 'Unknown error occurred.',
    vn: 'Xảy ra lỗi không xác định được.',
  },
  E_9100: {
    ja: '不明なエラーが発生しました。',
    en: 'Unknown error occurred.',
    vn: 'Xảy ra lỗi không xác định được.',
  },
  E_9900: {
    ja: '不明なエラーが発生しました。',
    en: 'Unknown error occurred.',
    vn: 'Xảy ra lỗi không xác định được.',
  },
  E_9999: {
    ja: '不明なエラーが発生しました。',
    en: 'Unknown error occurred.',
    vn: 'Xảy ra lỗi không xác định được.',
  },
  // FIXME メッセージは一旦不明なエラーとする
  E_L001: {
    ja: '不明なエラーが発生しました。',
    en: 'Unknown error occurred.',
    vn: 'Xảy ra lỗi không xác định được.',
  },
  E_L002: {
    ja: '不明なエラーが発生しました。',
    en: 'Unknown error occurred.',
    vn: 'Xảy ra lỗi không xác định được.',
  },
} as const;
