import { Box, Flex, Spinner, Stack, Progress, Text, HStack, Image, Button } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { localStorageKeys } from '../../constant/localStorage';
import { storyNoFromStoryId } from '../../constant/story-constants';
import { createStoryIdById } from '../../helper/story-helper';
import useStoryExercises from '../../hooks/useStoryExercises/useStoryExercises';
import StoryHeader from './Component/Header/StoryHeader';
import HeaderButtons from './Component/Header/HeaderButtons';
import FourChoicesQuestion from './Component/Question/FourChoicesQuestion/FourChoicesQuestion';
import SortQuestion from './Component/Question/SortQuestion/SortQuestion';
import { useHistory } from 'react-router-dom';
import { serverApi, requestServerApi } from '../../api/api';
import useErrorPage from '../../hooks/useErrorPage/useErrorPage';
import { Answer, Exercise } from '../../interfaces/exerciseInterface';
import { useEffect } from 'react';
import './index.scss';

type Props = {};
export type LangOption = 'ja' | 'kana' | 'en' | 'vn';
export type KanaOption = 'ja' | 'kana';

const StoryExercises: FC<Props> = ({}) => {
  const history = useHistory();
  const seasonId = Number(localStorage.getItem(localStorageKeys.seasonId));
  const episodeId = Number(localStorage.getItem(localStorageKeys.episodeId));
  const sceneId = Number(localStorage.getItem(localStorageKeys.sceneId));
  const storyId = createStoryIdById(seasonId, episodeId, sceneId);
  const exercises = useStoryExercises(storyId ? storyNoFromStoryId[storyId] : '');
  const questionLength = exercises?.contents.length;
  const exerciseString = localStorage.getItem(localStorageKeys.exerciseJson);
  const [currentQuestion, setCurrentQuestion] = useState<number>(1);
  const [langOption, setLangOption] = useState<LangOption>('ja');
  const [kanaOption, setKanaOption] = useState<KanaOption>(
    (localStorage.getItem(localStorageKeys.displayJPMode) as KanaOption) ?? 'kana',
  );
  const [answerOrder, setAnswerOrder] = useState<string>('');
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');
  const [shuffledAnswers, setShuffledAnswers] = useState<any>();
  const { apiErrorHandler } = useErrorPage();

  useEffect(() => {
    if (exerciseString && questionLength) {
      const exerciseJson: Exercise = JSON.parse(exerciseString);
      setCurrentQuestion(exerciseJson.currentQuestion);

      const langState = localStorage.getItem(localStorageKeys.translationFlg) ?? 'true';
      const systemLang = localStorage.getItem(localStorageKeys.systemLanguage) ?? 'ja';
      const lang = langState === 'true' ? (systemLang as LangOption) : ('ja' as LangOption);
      setLangOption(lang);
    }
  }, [questionLength]);

  useEffect(() => {
    setAnswerOrder('');
    shuffleAnswers();
  }, [currentQuestion, langOption, kanaOption, questionLength]);

  useEffect(() => {
    const systemLang = String(localStorage.getItem(localStorageKeys.systemLanguage));
    setLangOption(systemLang as LangOption);
  }, []);

  const shuffleAnswers = () => {
    if (questionLength && currentQuestion <= questionLength) {
      const QuestionDetail = exercises?.contents[currentQuestion - 1];
      if (QuestionDetail?.questionType.includes('Sort')) {
        setShuffledAnswers(shuffleArray([...QuestionDetail.answers]));
      } else {
        setShuffledAnswers(null);
      }
    }
  };

  const Contents = () => {
    return (
      <>
        <Stack
          className="custom-stack"
          bgSize="cover"
          boxSizing="content-box"
          bgColor="white"
          borderRadius="5px 5px 0 0"
          m={'auto'}
          height={'100vh'}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowY: 'auto',
          }}
        >
          <StoryHeader />
          <Flex width="100%">
            <HStack width="100%" ml={1} mr={1}>
              <Box display="flex" width={'100%'} alignItems="center" ml={1} mr={1}>
                <Box width={{ base: '90%', md: '95%' }} display="flex" justifyContent="center" alignItems="center">
                  <Progress width={'100%'} value={(currentQuestion / (questionLength ?? 1)) * 100} height="5px" />
                </Box>
                <Box
                  width={{ base: '10%', md: '5%' }}
                  display={'flex'}
                  alignItems="center"
                  justifyContent={'center'}
                  ml={2}
                >
                  <Text fontWeight={'medium'}>
                    {currentQuestion}/{questionLength}
                  </Text>
                </Box>
              </Box>
            </HStack>
          </Flex>
          <HeaderButtons
            currentQuestion={currentQuestion}
            langOption={langOption}
            setLangOption={setLangOption}
            kanaOption={kanaOption}
            setKanaOption={setKanaOption}
          />
          <Exercise />
        </Stack>
      </>
    );
  };

  const Exercise = () => {
    const currentExercise = exercises?.contents[currentQuestion - 1];
    if (!currentExercise) return null;

    return (
      <>
        {currentExercise.questionType.includes('FourChoices') ? (
          <FourChoicesQuestion
            baseQuestion={currentExercise}
            langOption={langOption}
            kanaOption={kanaOption}
            selectedAnswer={selectedAnswer}
            setSelectedAnswer={setSelectedAnswer}
            handleNextQuestion={handleNextQuestion}
          />
        ) : null}

        {currentExercise.questionType.includes('Sort') ? (
          <SortQuestion
            baseQuestion={currentExercise}
            langOption={langOption}
            kanaOption={kanaOption}
            answerOrder={answerOrder}
            setAnswerOrder={setAnswerOrder}
            shuffledAnswers={shuffledAnswers}
            handleNextQuestion={handleNextQuestion}
          />
        ) : null}
      </>
    );
  };

  const handleNextQuestion = (answer: Answer) => {
    if (currentQuestion <= (questionLength ?? 0)) {
      if (exerciseString) {
        const exerciseJson: Exercise = JSON.parse(exerciseString);
        exerciseJson.answers[currentQuestion] = answer;
        exerciseJson.currentQuestion = currentQuestion + 1;
        answer === Answer.CORRECT && exerciseJson.correctCount++;
        const jsonString = JSON.stringify(exerciseJson);
        localStorage.setItem(localStorageKeys.exerciseJson, jsonString);
        setAnswerOrder('');
        setSelectedAnswer('');
        if (currentQuestion === questionLength) {
          saveStudyRecord(exerciseJson);
        } else {
          setCurrentQuestion(currentQuestion + 1);
        }
      }
    }
  };

  const shuffleArray = (array: any[]) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const saveStudyRecord = (exerciseJson: Exercise) => {
    const studyResult: any = {
      chapter_id: Number(seasonId + 1) + '_SEASON_ID_' + Number(episodeId + 1) + '_CHAPTER_ID_' + Number(sceneId),
      correct_count: exerciseJson.correctCount,
      total_count: questionLength,
    };

    let apiResponse = requestServerApi<any>(() =>
      serverApi.mobileAppControllerPutStudyRecord(studyResult).catch((e) =>
        apiErrorHandler(e, {
          componentName: 'story-exercises',
          functionName: serverApi.organizationsControllerGetPastLessonList.name,
        }),
      ),
    );
    apiResponse.then((result) => {
      if (result.data.code === 200) {
        localStorage.removeItem(localStorageKeys.exerciseJson);
        history.push('/episodeCompletion');
      }
    });
  };

  return (
    <>
      {!exercises ? (
        <Flex>
          <Spinner />
        </Flex>
      ) : (
        <Contents />
      )}
    </>
  );
};
export default StoryExercises;
